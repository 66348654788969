import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import DropdownSelect from "./FormComponents/DropdownSelect";
import ModalOverlay from "./ModalOverlay";
import GetTestTokenForm from "./GetTestTokenForm";
import PageTitle from "./PageTitle";

function Navbar(props) {
  const { isLoggedIn, login, logout, walletAddress, gatewayAddress, userWalletAddress, isOwner } =
    props;
  const dropdownOptions = [
    {
      label: "مالک درگاه",
      name: "wallet-address",
      value: userWalletAddress || "0x0",
      icon: <span className="icon-wallet"></span>,
    },
    {
      label: "کیف پول درگاه",
      name: "wallet-address",
      value: walletAddress || "0x0",
      icon: <span className="icon-wallet"></span>,
    },
    {
      label: "آدرس درگاه",
      name: "gateway-address",
      value: gatewayAddress || "0x0",
      icon: <span className="icon-gateway"></span>,
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(dropdownOptions[0]);
  useEffect(() => {
    const newSelected = dropdownOptions.filter((option) => selected.name === option.name)[0];
    if (newSelected) setSelected(newSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, gatewayAddress, userWalletAddress]);

  const handleFaucet = useCallback(() => {
    if (isLoggedIn) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturn = useCallback(() => {
    //console.log("returning... ");
    setShowModal(false);
  }, []);
  return (
    <>
      <header>
        <div className="header-style">
          <PageTitle className="block-page-title" />
          {isLoggedIn && (
            <button className="header-button" onClick={handleFaucet}>
              دریافت توکن تست
            </button>
          )}
          <div className={`wallet-address-style${isOwner ? " has-dropdown" : ""}`}>
            {isOwner ? (
              <DropdownSelect
                name="display-type"
                id="display-type"
                parentClass="display-type"
                selectClass="display-type-select"
                options={dropdownOptions}
                onChangeAction={setSelected}
              />
            ) : (
              <label htmlFor="wallet-address" className="wallet-address-logo">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(userWalletAddress);
                  }}
                  className="copy-to-clipboard"
                >
                  <span className="icon-wallet"></span>
                </button>
              </label>
            )}

            <input
              disabled
              type="text"
              className="wallet-address-text"
              value={isOwner ? selected?.value : userWalletAddress}
            />
          </div>
          <button className="logout-button" onClick={isLoggedIn ? logout : login}>
            <span className={isLoggedIn ? "icon-logout" : "icon-login"}></span>
          </button>
        </div>
      </header>
      {isLoggedIn && showModal && (
        <ModalOverlay className="test-token-form">
          <GetTestTokenForm handleReturn={handleReturn} />
        </ModalOverlay>
      )}
    </>
  );
}

Navbar.propTypes = {
  walletAddress: PropTypes.string,
  gatewayAddress: PropTypes.string,
  userWalletAddress: PropTypes.string,
  logout: PropTypes.func,
  login: PropTypes.func,
  isOwner: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};

Navbar.defaultProps = {
  isOwner: true,
};

export default Navbar;
