import * as actionTypes from "../actionTypes";
import * as blockchainMethods from "../async/blockchainMethods";
import { setConfirmation } from "./successActions";

// sync actions:
export const setWalletList = (gatewayWallets) => ({
  type: actionTypes.SET_WALLET_LIST,
  payload: gatewayWallets,
});
export const setWeb3Object = (web3Object) => ({
  type: actionTypes.LOAD_WEB3,
  payload: web3Object,
});
export const setWalletData = (walletData) => ({
  type: actionTypes.SET_WALLET,
  payload: walletData,
});
export const logout = (walletData) => ({
  type: actionTypes.LOGOUT,
  payload: walletData,
});
export const setTransactionResults = (results) => ({
  type: actionTypes.SET_RESULTS,
  payload: results,
});
export const resetTransactionResults = () => ({
  type: actionTypes.RESET_RESULTS,
});
export const setUpdatedWallet = (updatedWallet) => ({
  type: actionTypes.SET_UPDATED_WALLET,
  payload: updatedWallet,
});
// async actions:

export const loadWeb3WalletConnect = () => {
  return async (dispatch) => {
    try {
      const web3Object = await blockchainMethods.loadWeb3WalletConnect();
      dispatch(setWeb3Object(web3Object));
      return web3Object;
    } catch (error) {
      throw error;
    }
  };
};

export const loadWeb3Metamask = () => {
  return async (dispatch) => {
    try {
      const web3Object = await blockchainMethods.loadWeb3Metamask();
      dispatch(setWeb3Object(web3Object));
      return web3Object;
    } catch (error) {
      throw error;
    }
  };
};

export const loadBlockchainData = (web3Object) => {
  return async (dispatch) => {
    try {
      const walletData = await blockchainMethods.loadWalletData(web3Object);
      dispatch(setWalletData(walletData));
      return walletData;
    } catch (err) {
      throw err;
    }
  };
};
export const loadGatewayWalletData = (web3Object, gatewayWallets, blockchainData) => {
  return async (dispatch) => {
    try {
      const gatewayWalletList = await blockchainMethods.loadERC20Tokens(
        web3Object,
        gatewayWallets,
        blockchainData,
      );
      dispatch(setWalletList(gatewayWalletList));
      return gatewayWalletList;
    } catch (err) {
      throw err;
    }
  };
};

export const updateWalletInfo = (wallet) => {
  return async (dispatch, getState) => {
    const { blockchainData } = getState().blockchainData;
    console.log("running update method");
    const updatedWallet = await blockchainMethods.updateWalletInfo(wallet, blockchainData);
    console.log(updatedWallet);
    dispatch(setUpdatedWallet(updatedWallet));
  };
};

export const getMissingReciepts = (transactionHash) => {
  return async (dispatch, getState) => {
    const { web3 } = getState().blockchainData.blockchainData;
    const { transactionHistory } = getState().success;
    const currentBlock = await web3.eth.getBlockNumber();
    const currentBlockHash = await web3.eth.getBlock(currentBlock).hash;
    for (const trHash in transactionHistory) {
      console.log(trHash, transactionHistory[trHash]);
      // No reciept is available:
      if (!transactionHistory[trHash].reciept) {
        const reciept = await blockchainMethods.getPendingTransactionData(trHash, web3);
        if (reciept) {
          // ToDo: Show transaction success Notification
          setConfirmation(currentBlock - reciept.blockNumber, reciept, currentBlockHash);
        }
      } else if (transactionHistory[trHash]?.number < 10) {
        const reciept = await blockchainMethods.getPendingTransactionData(trHash, web3);
        if (reciept) {
          // ToDo: Show transaction success Notification
          setConfirmation(
            currentBlock - reciept.blockNumber,
            transactionHistory[trHash].reciept,
            currentBlockHash,
          );
        }
      }
    }
  };
};

export const getTestToken = (selectedCurrency) => {
  return async (dispatch, getState) => {
    const { blockchainData } = getState().blockchainData;
    const response = await blockchainMethods.getTestToken(selectedCurrency, blockchainData);
    const { to, value } = response.events.Transfer.returnValues;
    const result = {
      message: "نوع تراکنش: دریافت توکن .",
      action: "testToken",
      params: [
        {
          label: "آدرس کیف پول",
          value: to,
        },
        {
          label: "مبلغ",
          value: selectedCurrency.fromWeiFunction(value, 6),
        },
      ],
      transactionHash: response.transactionHash,
      requiredConfirmations: 0,
    };
    dispatch(setTransactionResults(result));
    console.log(response);
  };
};
